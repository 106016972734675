import * as React from 'react';
import styled from 'styled-components';
import GlobalStyles from '../styles/globalStyles';
import { Helmet } from 'react-helmet';
import AccountTemplate from "../components/navigationBar/accountTemplate/accountTemplate";


const MainContainer = styled.div`
	width: 100%;
`;

const Header = styled.h1`
	font-family: "Gotham-Light", arial, sans-serif;
	font-weight: normal !important;
	color: #ee1f23;
	margin: 0 131px 0 131px;
	font-size: 45px;
	line-height: 50px;
	letter-spacing: 0.02em;
	margin-bottom: 35px;

	@media (max-width: 767px) {
		font-size: 35px;
		line-height: 40px;
		margin: 0 25px 0 25px;
	}
`;

const LinkStyle = styled.a`
	text-decoration: none !important;
	text-transform: uppercase;
	font-size: 15px;
	line-height: 15px;
	font-family: Gotham-Bold, arial, sans-serif;
	font-weight: bold !important;
	color: #9b1b1f;
	text-align: center;
	margin: 0 131px 0 131px;

	@media (max-width: 767px) {
		line-height: 40px;
		margin: 0 60px 0 25px;
	}

	:hover {
		text-decoration: none;
		color: #ee1f23;
		outline: none;
	}
`;

const NotFoundPage = () => {
	return (
		<>
			<GlobalStyles />
			<Helmet>
				<title>SuperPumper :: Page Not Found</title>
			</Helmet>
			<AccountTemplate />
			<MainContainer>
				<Header>Page Not Found</Header>
				<LinkStyle href={"/"}>BACK TO HOME</LinkStyle>
			</MainContainer>
		</>
	);
};

export default NotFoundPage;
